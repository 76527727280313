import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useState, useCallback, ReactNode, ReactElement, act } from "react";

export interface IDialogButton {
  label: string;
  onClick: () => void;
}

export interface IDialogProps {
  title?: string | ReactNode;
  content?: ReactNode;
  backButton?: ReactElement;
  onCloseButtonClicked?: () => void;
  onDismissed?: () => void;
  actions?: IDialogButton[];
  primaryButton?: IDialogButton;
  secondaryButton?: IDialogButton;
}

export const useGetDialog = () => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string | ReactNode>();
  const [content, setContent] = useState<ReactNode>();
  const [backButton, setBackButton] = useState<ReactElement>();
  const [primaryButton, setPrimaryButton] = useState<IDialogButton>();
  const [secondaryButton, setSecondaryButton] = useState<IDialogButton>();
  const [onCloseButtonClicked, setOnCloseButtonClicked] =
    useState<() => void>();
  const [actions, setActions] = useState<IDialogButton[]>();

  const toggleDialog = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  const updateDialogInfo = useCallback(
    ({
      title,
      content,
      backButton,
      onCloseButtonClicked,
      actions,
      primaryButton,
      secondaryButton,
    }: IDialogProps = {}) => {
      setTitle(title);
      setContent(content);
      setBackButton(backButton);
      setOnCloseButtonClicked(onCloseButtonClicked);
      setActions(actions);
      setPrimaryButton(primaryButton);
      setSecondaryButton(secondaryButton);
    },
    []
  );
  // Render function for the dialog
  const onRender = useCallback(
    () => (
      <Dialog open={open} onClose={toggleDialog} maxWidth="sm" fullWidth>
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{content}</DialogContent>

        {(primaryButton || secondaryButton) && (
          <DialogActions>
            {secondaryButton && (
              <Button key={"secondaryButton"} onClick={secondaryButton.onClick}>
                {secondaryButton.label}
              </Button>
            )}
            {primaryButton && (
              <Button key={"primaryButton"} onClick={primaryButton.onClick}>
                {primaryButton.label}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    ),
    [content, open, primaryButton, secondaryButton, title, toggleDialog]
  );

  return {
    toggleDialog: toggleDialog,
    render: onRender,
    update: updateDialogInfo,
    close: closeDialog,
  };
};
