import { PrivateProductData } from "../../../packages/cmsapi";

export type ProductAction =
  | { type: "SET_PRODUCT"; value: any }
  | { type: "SET_FIELD"; fieldName: keyof PrivateProductData; value: any }
  | { type: "RESET"; payload: PrivateProductData }
  | { type: "LOAD_PRODUCT"; payload: PrivateProductData };

export const productReducer = (
  state: PrivateProductData,
  action: ProductAction
): PrivateProductData => {
  switch (action.type) {
    case "SET_PRODUCT":
      return { ...action.value };
    case "SET_FIELD":
      return { ...state, [action.fieldName]: action.value };
    case "RESET":
      return action.payload;
    case "LOAD_PRODUCT":
      return action.payload;
    default:
      return state;
  }
};
