import { CssBaseline, ThemeProvider } from "@mui/material";
import "./App.css";
import AppContent from "./AppContent";
import { theme as VideomobileTheme } from "./styles";

function App() {
  return (
    <ThemeProvider theme={VideomobileTheme}>
      <CssBaseline />
      <AppContent />
    </ThemeProvider>
  );
}

export default App;
