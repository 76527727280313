import {
  ProductTypeData,
  RestockingData,
  SaleData,
  WebApi,
} from "./../../packages/cmsapi/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CmsApi,
  Configuration,
  ConfigurationParameters,
  PrivateBrandData,
  PrivateProductData,
  SlideshowData,
} from "../../packages/cmsapi";
import {
  convertObjectToFormDataMultipleFiles,
  convertObjectToFormDataSingleFile,
} from "../../utils/utils";
import { videomobileCmsApiKey } from "../../constants";

const createApiConfig = () => {
  const token = localStorage.getItem(videomobileCmsApiKey);
  const configParameters: ConfigurationParameters = {
    // basePath: "http://192.168.178.73:8080",
    // basePath: "https://api.testvideomobile.org",
    basePath: "https://api.videomobile.al",
    baseOptions: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  };

  return new Configuration(configParameters);
};

export const getProducts = createAsyncThunk(
  "products/getProducts",
  async () => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.getProducts();
      return response.data;
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "cms/updateProduct",
  async ({
    productId,
    productData,
  }: {
    productId: string;
    productData: PrivateProductData;
  }) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.updateProduct(productId, {
        productData: productData,
      });
      return response.data;
    } catch (error) {
      console.log("error updating product");
    }
  }
);

export const addPicturesToProduct = createAsyncThunk(
  "cms/addPicturesToProduct",
  async ({ productId, files }: { productId: string; files?: File[] }) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.addPicturesToProduct(productId, files);
      return response.data;
    } catch (error) {
      console.log("error updating product");
    }
  }
);

export const createProduct = createAsyncThunk(
  "cms/createProduct",
  async ({
    productData,
    files,
  }: {
    productData: PrivateProductData;
    files?: File[];
  }) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.createProduct({
        data: convertObjectToFormDataMultipleFiles(productData, files),
      });
      return response.data;
    } catch (error) {
      console.log("error updating product");
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "cms/deleteProduct",
  async (productId: string) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteProduct(productId);
      return response.data;
    } catch (error) {
      console.log("error deleting product");
    }
  }
);

export const deleteProducts = createAsyncThunk(
  "cms/deleteProducts",
  async (productIds: string[]) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteProducts({ productIds: productIds });
      return response.data;
    } catch (error) {
      console.log("error deleting products");
    }
  }
);

export const getBrands = createAsyncThunk("brands/getBrands", async () => {
  const brandApi = new CmsApi(createApiConfig());
  try {
    const response = await brandApi.getBrands();

    return response.data;
  } catch (error) {
    console.log("Error fetching brands");
  }
});

export const createBrand = createAsyncThunk(
  "cms/createBrand",
  async ({ brandData, file }: { brandData: PrivateBrandData; file?: File }) => {
    console.log(brandData);
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.createBrand({
        data: convertObjectToFormDataSingleFile(brandData, file),
      });
      return response.data;
    } catch (error) {
      console.log("error updating brand");
    }
  }
);

export const updateBrand = createAsyncThunk(
  "cms/updateBrand",
  async ({
    brandId,
    brandData,
    file,
  }: {
    brandId: string;
    brandData: PrivateBrandData;
    file?: File;
  }) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.updateBrand(brandId, {
        data: convertObjectToFormDataSingleFile(brandData, file),
      });
      return response.data;
    } catch (error) {
      console.log("error updating brand");
    }
  }
);

export const deleteBrand = createAsyncThunk(
  "cms/deleteBrand",
  async (brandId: string) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteBrand(brandId);
      return response.data;
    } catch (error) {
      console.log("error deleting brand");
    }
  }
);

export const deleteBrands = createAsyncThunk(
  "cms/deleteBrands",
  async (brandIds: string[]) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteBrands({ brandIds: brandIds });
      return response.data;
    } catch (error) {
      console.log("error deleting brands");
    }
  }
);

export const getSlideshows = createAsyncThunk(
  "slideshow/getSlideshows",
  async () => {
    const slideshowApi = new WebApi(createApiConfig());

    try {
      const response = await slideshowApi.getSlideshowData();
      return response.data;
    } catch (error) {
      console.log("Error fetching sales");
    }
  }
);

export const createSlideshow = createAsyncThunk(
  "cms/createSlideshow",
  async ({
    slideshowData,
    file,
  }: {
    slideshowData: SlideshowData;
    file?: File;
  }) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.createSlideshow({
        data: convertObjectToFormDataSingleFile(slideshowData, file),
      });
      return response.data;
    } catch (error) {
      console.log("error updating slideshow");
    }
  }
);

export const updateSlideshow = createAsyncThunk(
  "cms/updateSlideshow",
  async ({
    slideshowId,
    slideshowData,
    file,
  }: {
    slideshowId: string;
    slideshowData: SlideshowData;
    file?: File;
  }) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.updateSlideshow(slideshowId, {
        data: convertObjectToFormDataSingleFile(slideshowData, file),
      });
      return response.data;
    } catch (error) {
      console.log("error updating slideshow");
    }
  }
);

export const deleteSlideshow = createAsyncThunk(
  "cms/deleteSlideshow",
  async (slideshowId: string) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteSlideshow(slideshowId);
      return response.data;
    } catch (error) {
      console.log("error deleting slideshow");
    }
  }
);

export const deleteSlideshows = createAsyncThunk(
  "cms/deleteSlideshows",
  async (slideshowIds: string[]) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteSlideshows({
        slideshowIds: slideshowIds,
      });
      return response.data;
    } catch (error) {
      console.log("error deleting slideshows");
    }
  }
);

export const getSales = createAsyncThunk("cms/getSales", async () => {
  const slideshowApi = new CmsApi(createApiConfig());

  try {
    const response = await slideshowApi.getSales();
    return response.data;
  } catch (error) {
    console.log("Error fetching sales");
  }
});

export const createSale = createAsyncThunk(
  "cms/createSale",
  async (saleData: SaleData) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.createSale(saleData);
      return response.data;
    } catch (error) {
      console.log("error updating sale");
    }
  }
);

export const updateSale = createAsyncThunk(
  "cms/updateSale",
  async ({ saleId, saleData }: { saleId: string; saleData: SaleData }) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.updateSale(saleId, saleData);
      return response.data;
    } catch (error) {
      console.log("error updating sale");
    }
  }
);

export const deleteSale = createAsyncThunk(
  "cms/deleteSale",
  async (saleId: string) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteSale(saleId);
      return response.data;
    } catch (error) {
      console.log("error updating sale");
    }
  }
);

export const deleteSales = createAsyncThunk(
  "cms/deleteSales",
  async (saleIds: string[]) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteSales({ saleIds: saleIds });
      return response.data;
    } catch (error) {
      console.log("error updating sale");
    }
  }
);

export const getRestockings = createAsyncThunk(
  "cms/getRestockings ",
  async () => {
    const slideshowApi = new CmsApi(createApiConfig());

    try {
      const response = await slideshowApi.getRestocking();
      return response.data;
    } catch (error) {
      console.log("Error fetching sales");
    }
  }
);

export const createRestocking = createAsyncThunk(
  "cms/createRestocking",
  async (restockingData: RestockingData) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.createRestocking(restockingData);
      return response.data;
    } catch (error) {
      console.log("error updating Restocking");
    }
  }
);

export const updateRestocking = createAsyncThunk(
  "cms/updateRestocking",
  async ({
    restockingId,
    restockingData,
  }: {
    restockingId: string;
    restockingData: RestockingData;
  }) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.updateRestocking(
        restockingId,
        restockingData
      );
      return response.data;
    } catch (error) {
      console.log("error updating Restocking");
    }
  }
);

export const deleteRestocking = createAsyncThunk(
  "cms/deleteRestocking",
  async (restockingId: string) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteRestocking(restockingId);
      return response.data;
    } catch (error) {
      console.log("error deleting restocking");
    }
  }
);

export const deleteRestockings = createAsyncThunk(
  "cms/deleteRestockings",
  async (restockingIds: string[]) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteRestockings({
        restockingIds: restockingIds,
      });
      return response.data;
    } catch (error) {
      console.log("error deleting restockings");
    }
  }
);

export const getProductTypes = createAsyncThunk(
  "productTypes/getProductTypes",
  async () => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.getProductTypes();

      return response.data;
    } catch (error) {
      console.log("Error fetching productTypes");
    }
  }
);

export const createProductType = createAsyncThunk(
  "cms/createProductType",
  async (productTypeData: ProductTypeData) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.createProductType(productTypeData);
      return response.data;
    } catch (error) {
      console.log("error updating productType");
    }
  }
);

export const deleteProductTypes = createAsyncThunk(
  "cms/deleteProductType",
  async (productTypeIds: string[]) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.deleteProductTypes({
        productTypeIds: productTypeIds,
      });
      return response.data;
    } catch (error) {
      console.log("error deleting productTypes");
    }
  }
);

export const updateProductType = createAsyncThunk(
  "cms/updateProductType",
  async ({
    productTypeId,
    productTypeData,
  }: {
    productTypeId: string;
    productTypeData: ProductTypeData;
  }) => {
    const cmsApi = new CmsApi(createApiConfig());
    try {
      const response = await cmsApi.updateProductType(
        productTypeId,
        productTypeData
      );
      return response.data;
    } catch (error) {
      console.log("error updating productType");
    }
  }
);
