import { SlideshowData } from "../../../packages/cmsapi";

export type SlideshowAction =
  | { type: "SET_SLIDESHOW"; value: any }
  | { type: "SET_FIELD"; fieldName: keyof SlideshowData; value: any }
  | { type: "RESET"; payload: SlideshowData }
  | { type: "LOAD_SLIDESHOW"; payload: SlideshowData };

export const SlideshowReducer = (
  state: SlideshowData,
  action: SlideshowAction
): SlideshowData => {
  switch (action.type) {
    case "SET_SLIDESHOW":
      return { ...action.value };
    case "SET_FIELD":
      return { ...state, [action.fieldName]: action.value };
    case "RESET":
      return action.payload;
    case "LOAD_SLIDESHOW":
      return action.payload;
    default:
      return state;
  }
};
