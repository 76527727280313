import {
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../store/store";
import { Dispatch } from "react";
import { Link, Tooltip } from "@mui/material";
import { deleteRestockings } from "../store/asyncThunk/productThunk";

import { useNavigate } from "react-router-dom";
import { useGetDialog } from "./useGetDialog";
import { useRequestDataIfNeeded } from "./useRequestDataIfNeeded";
import { routesList } from "../constants";

export const useRestockingDataGridInfo = () => {
  const productsInfoMap = useAppSelector((state) => state.productInfoMap);
  const restockings = useAppSelector((state) => state.restockings);
  const deleteDialog = useGetDialog();
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  useRequestDataIfNeeded(["products", "restockings"]);

  const columns: GridColDef[] = [
    {
      field: "productId",
      headerName: "Product Bought",
      width: 150,
      description: "Emri i produktit te blere",
      renderCell: (params) => {
        return (
          <Tooltip title={productsInfoMap[params.row.productId]?.name}>
            <Link
              underline="hover"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                nav(routesList.products + `/${params.row.id}}`);
              }}
            >
              {productsInfoMap[params.row.productId]?.name}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: "receiptId",
      headerName: "Receipt Id",
      type: "string",
      minWidth: 120,
      align: "left",
      headerAlign: "left",
      description: "Numri i fatures",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.receiptId}>
            <span>{params.row.receiptId}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "restockDate",
      headerName: "Restock Date",
      type: "date",
      width: 100,
      align: "left",
      headerAlign: "left",
      valueGetter: (value: string) => {
        return new Date(value);
      },
      description: "Data ne te cilen ka arritur produkti ne magazine",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.restockDate}>
            <span>{params.row.restockDate}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "restockSize",
      headerName: "Restock Size",
      type: "number",
      minWidth: 50,
      align: "left",
      headerAlign: "left",
      description: "Sa produkt eshte blere",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.restockSize}>
            <span>{params.row.restockSize}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      width: 120,
      headerAlign: "left",
      align: "left",
      description: "Cmimi i blerjes se produktit",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.price}>
            <span>{params.row.price}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "otherInfo",
      headerName: "Other Info",
      width: 380,
      flex: 1,
      description: "Informacione ekstra mbi blerjen",
      renderCell: (params) => (
        <Tooltip
          title={params.value || ""}
          arrow
          placement="bottom"
          sx={{ maxWidth: 10 }}
        >
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.value}
          </span>
        </Tooltip>
      ),
    },
  ];

  const handleDeleteRows = (
    rows: GridRowSelectionModel,
    setSelectedRows: Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ) => {
    deleteDialog.update({
      title: `Are you sure you want to delete ${rows.length} Restockings`,
      primaryButton: {
        label: "Yes",
        onClick: () => {
          dispatch(deleteRestockings(rows as string[]));
          deleteDialog.close();
          setSelectedRows([]);
        },
      },
      secondaryButton: {
        label: "No",
        onClick: () => {
          deleteDialog.close();
        },
      },
    });
    deleteDialog.toggleDialog();
  };

  const handleRowDoubleClick = (params: GridRowParams) => {
    nav(routesList.restocking + `/${params.row.id}`);
  };

  const handleAddInstance = () => {
    nav(routesList.addRestocking);
  };

  return {
    gridProps: {
      rows: restockings.list,
      columns: columns,
      handleDeleteRows: handleDeleteRows,
      handleAddInstance: handleAddInstance,
      handleRowDoubleClick: handleRowDoubleClick,
    },
    dialog: deleteDialog,
  };
};
