import { useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../store/store";
import {
  getBrands,
  getProducts,
  getProductTypes,
  getRestockings,
  getSales,
  getSlideshows,
} from "../store/asyncThunk/productThunk";
import { State } from "../store/slices/productSlice";

export const useRequestDataIfNeeded = (
  dataToRequest: (
    | "products"
    | "brands"
    | "slideshows"
    | "sales"
    | "restockings"
    | "productTypes"
  )[] = []
) => {
  const products = useAppSelector((state) => state.products);
  const brands = useAppSelector((state) => state.brands);
  const slideshows = useAppSelector((state) => state.slideshows);
  const sales = useAppSelector((state) => state.sales);
  const restockings = useAppSelector((state) => state.restockings);
  const productTypes = useAppSelector((state) => state.productTypes);

  const dispatch = useAppDispatch();

  const requestProducts = useMemo(() => {
    return !dataToRequest || dataToRequest.includes("products");
  }, [dataToRequest]);

  const requestBrands = useMemo(() => {
    return !dataToRequest || dataToRequest.includes("brands");
  }, [dataToRequest]);

  const requestSlideshows = useMemo(() => {
    return !dataToRequest || dataToRequest.includes("slideshows");
  }, [dataToRequest]);

  const requestSales = useMemo(() => {
    return !dataToRequest || dataToRequest.includes("sales");
  }, [dataToRequest]);

  const requestRestockings = useMemo(() => {
    return !dataToRequest || dataToRequest.includes("restockings");
  }, [dataToRequest]);

  const requestProductTypes = useMemo(() => {
    return !dataToRequest || dataToRequest.includes("productTypes");
  }, [dataToRequest]);

  useEffect(() => {
    if (
      requestProducts &&
      products.dataState !== State.Fulfilled &&
      products.dataState !== State.Pending
    ) {
      dispatch(getProducts());
    }
  }, [dispatch, products.dataState, requestProducts]);

  useEffect(() => {
    if (
      requestBrands &&
      brands.dataState !== State.Fulfilled &&
      brands.dataState !== State.Pending
    ) {
      dispatch(getBrands());
    }
  }, [brands.dataState, dispatch, requestBrands]);

  useEffect(() => {
    if (
      requestSlideshows &&
      slideshows.dataState !== State.Fulfilled &&
      slideshows.dataState !== State.Pending
    ) {
      dispatch(getSlideshows());
    }
  }, [dispatch, requestSlideshows, slideshows.dataState]);

  useEffect(() => {
    if (
      requestSales &&
      sales.dataState !== State.Fulfilled &&
      sales.dataState !== State.Pending
    ) {
      dispatch(getSales());
    }
  }, [dispatch, requestSales, sales.dataState]);

  useEffect(() => {
    if (
      requestRestockings &&
      restockings.dataState !== State.Fulfilled &&
      restockings.dataState !== State.Pending
    ) {
      dispatch(getRestockings());
    }
  }, [dispatch, requestRestockings, restockings.dataState]);

  useEffect(() => {
    if (
      requestProductTypes &&
      productTypes.dataState !== State.Fulfilled &&
      productTypes.dataState !== State.Pending
    ) {
      dispatch(getProductTypes());
    }
  }, [dispatch, requestProductTypes, productTypes.dataState]);
};
