/* tslint:disable */
/* eslint-disable */
/**
 * Videomobile API
 * API for videomobile main website
 *
 * The version of the OpenAPI document: 1.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface BrandData
 */
export interface BrandData {
    /**
     * ID of product
     * @type {string}
     * @memberof BrandData
     */
    'id': string;
    /**
     * URL of hosted image
     * @type {string}
     * @memberof BrandData
     */
    'pictureUrl'?: string;
    /**
     * name of brand
     * @type {string}
     * @memberof BrandData
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DeleteBrandsRequest
 */
export interface DeleteBrandsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteBrandsRequest
     */
    'brandIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteProductTypesRequest
 */
export interface DeleteProductTypesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteProductTypesRequest
     */
    'productTypeIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteProductsRequest
 */
export interface DeleteProductsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteProductsRequest
     */
    'productIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteRestockingsRequest
 */
export interface DeleteRestockingsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteRestockingsRequest
     */
    'restockingIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteSalesRequest
 */
export interface DeleteSalesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteSalesRequest
     */
    'saleIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteSlideshowsRequest
 */
export interface DeleteSlideshowsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteSlideshowsRequest
     */
    'slideshowIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PrivateBrandData
 */
export interface PrivateBrandData {
    /**
     * ID of product
     * @type {string}
     * @memberof PrivateBrandData
     */
    'id': string;
    /**
     * URL of hosted image
     * @type {string}
     * @memberof PrivateBrandData
     */
    'pictureUrl'?: string;
    /**
     * name of brand
     * @type {string}
     * @memberof PrivateBrandData
     */
    'name': string;
    /**
     * True when a brand is soft deleted
     * @type {boolean}
     * @memberof PrivateBrandData
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface PrivateProductData
 */
export interface PrivateProductData {
    /**
     * ID of product
     * @type {string}
     * @memberof PrivateProductData
     */
    'id': string;
    /**
     * Id of brand of product
     * @type {string}
     * @memberof PrivateProductData
     */
    'brandId'?: string;
    /**
     * Name of product
     * @type {string}
     * @memberof PrivateProductData
     */
    'name': string;
    /**
     * Description of product
     * @type {string}
     * @memberof PrivateProductData
     */
    'description'?: string;
    /**
     * Price of product
     * @type {number}
     * @memberof PrivateProductData
     */
    'price': number;
    /**
     * Price of product after discount
     * @type {number}
     * @memberof PrivateProductData
     */
    'discountPrice'?: number;
    /**
     * The date when the discount ends
     * @type {string}
     * @memberof PrivateProductData
     */
    'discountUntil'?: string;
    /**
     * How many of this product are in stock
     * @type {number}
     * @memberof PrivateProductData
     */
    'stock'?: number;
    /**
     * List of urls of hosted product pictures
     * @type {Array<string>}
     * @memberof PrivateProductData
     */
    'picturesUrl': Array<string>;
    /**
     * The date until product is displayed as coming soon
     * @type {string}
     * @memberof PrivateProductData
     */
    'comingSoonUntil'?: string;
    /**
     * The date until product is displayed as new product
     * @type {string}
     * @memberof PrivateProductData
     */
    'newProductUntil'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrivateProductData
     */
    'suggestions'?: Array<string>;
    /**
     * The product reference id
     * @type {string}
     * @memberof PrivateProductData
     */
    'referenceId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrivateProductData
     */
    'types'?: Array<string>;
    /**
     * True when a product is soft deleted
     * @type {boolean}
     * @memberof PrivateProductData
     */
    'isDeleted'?: boolean;
    /**
     * True when a product is not visible in main web
     * @type {boolean}
     * @memberof PrivateProductData
     */
    'isHidden'?: boolean;
}
/**
 * 
 * @export
 * @interface PrivateProductData1
 */
export interface PrivateProductData1 {
    /**
     * ID of product
     * @type {string}
     * @memberof PrivateProductData1
     */
    'id': string;
    /**
     * Id of brand of product
     * @type {string}
     * @memberof PrivateProductData1
     */
    'brandId'?: string;
    /**
     * Name of product
     * @type {string}
     * @memberof PrivateProductData1
     */
    'name': string;
    /**
     * Description of product
     * @type {string}
     * @memberof PrivateProductData1
     */
    'description'?: string;
    /**
     * Price of product
     * @type {number}
     * @memberof PrivateProductData1
     */
    'price': number;
    /**
     * Price of product after discount
     * @type {number}
     * @memberof PrivateProductData1
     */
    'discountPrice'?: number;
    /**
     * The date when the discount ends
     * @type {string}
     * @memberof PrivateProductData1
     */
    'discountUntil'?: string;
    /**
     * How many of this product are in stock
     * @type {number}
     * @memberof PrivateProductData1
     */
    'stock'?: number;
    /**
     * List of urls of hosted product pictures
     * @type {Array<string>}
     * @memberof PrivateProductData1
     */
    'picturesUrl': Array<string>;
    /**
     * The date until product is displayed as coming soon
     * @type {string}
     * @memberof PrivateProductData1
     */
    'comingSoonUntil'?: string;
    /**
     * The date until product is displayed as new product
     * @type {string}
     * @memberof PrivateProductData1
     */
    'newProductUntil'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrivateProductData1
     */
    'suggestions'?: Array<string>;
    /**
     * The product reference id
     * @type {string}
     * @memberof PrivateProductData1
     */
    'referenceId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PrivateProductData1
     */
    'types'?: Array<string>;
    /**
     * True when a product is soft deleted
     * @type {boolean}
     * @memberof PrivateProductData1
     */
    'isDeleted'?: boolean;
    /**
     * True when a product is not visible in main web
     * @type {boolean}
     * @memberof PrivateProductData1
     */
    'isHidden'?: boolean;
}
/**
 * 
 * @export
 * @interface ProductData
 */
export interface ProductData {
    /**
     * ID of product
     * @type {string}
     * @memberof ProductData
     */
    'id': string;
    /**
     * Id of brand of product
     * @type {string}
     * @memberof ProductData
     */
    'brandId'?: string;
    /**
     * Name of product
     * @type {string}
     * @memberof ProductData
     */
    'name': string;
    /**
     * Description of product
     * @type {string}
     * @memberof ProductData
     */
    'description'?: string;
    /**
     * Price of product
     * @type {number}
     * @memberof ProductData
     */
    'price': number;
    /**
     * Price of product after discount
     * @type {number}
     * @memberof ProductData
     */
    'discountPrice'?: number;
    /**
     * The date when the discount ends
     * @type {string}
     * @memberof ProductData
     */
    'discountUntil'?: string;
    /**
     * How many of this product are in stock
     * @type {number}
     * @memberof ProductData
     */
    'stock'?: number;
    /**
     * List of urls of hosted product pictures
     * @type {Array<string>}
     * @memberof ProductData
     */
    'picturesUrl': Array<string>;
    /**
     * The date until product is displayed as coming soon
     * @type {string}
     * @memberof ProductData
     */
    'comingSoonUntil'?: string;
    /**
     * The date until product is displayed as new product
     * @type {string}
     * @memberof ProductData
     */
    'newProductUntil'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductData
     */
    'suggestions'?: Array<string>;
    /**
     * The product reference id
     * @type {string}
     * @memberof ProductData
     */
    'referenceId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductData
     */
    'types'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProductTypeData
 */
export interface ProductTypeData {
    /**
     * ID of product
     * @type {string}
     * @memberof ProductTypeData
     */
    'id': string;
    /**
     * name of brand
     * @type {string}
     * @memberof ProductTypeData
     */
    'name': string;
    /**
     * list of product uuids that have this type
     * @type {Array<string>}
     * @memberof ProductTypeData
     */
    'products'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RestockingData
 */
export interface RestockingData {
    /**
     * ID of product
     * @type {string}
     * @memberof RestockingData
     */
    'id': string;
    /**
     * Id of product that is sold
     * @type {string}
     * @memberof RestockingData
     */
    'productId': string;
    /**
     * Id of receipt
     * @type {string}
     * @memberof RestockingData
     */
    'receiptId'?: string;
    /**
     * The date when the sale happend
     * @type {string}
     * @memberof RestockingData
     */
    'restockDate'?: string;
    /**
     * How many of this product were sold
     * @type {number}
     * @memberof RestockingData
     */
    'restockSize'?: number;
    /**
     * Price of product
     * @type {number}
     * @memberof RestockingData
     */
    'price'?: number;
    /**
     * Other information regarding the sale
     * @type {string}
     * @memberof RestockingData
     */
    'otherInfo'?: string;
    /**
     * 
     * @type {PrivateProductData}
     * @memberof RestockingData
     */
    'product'?: PrivateProductData;
}
/**
 * 
 * @export
 * @interface SaleData
 */
export interface SaleData {
    /**
     * ID of product
     * @type {string}
     * @memberof SaleData
     */
    'id': string;
    /**
     * Id of product that is sold
     * @type {string}
     * @memberof SaleData
     */
    'productId': string;
    /**
     * Id of receipt
     * @type {string}
     * @memberof SaleData
     */
    'receiptId'?: string;
    /**
     * The date when the sale happend
     * @type {string}
     * @memberof SaleData
     */
    'saleDate'?: string;
    /**
     * How many of this product were sold
     * @type {number}
     * @memberof SaleData
     */
    'saleSize': number;
    /**
     * Price of product
     * @type {number}
     * @memberof SaleData
     */
    'price'?: number;
    /**
     * Other information regarding the sale
     * @type {string}
     * @memberof SaleData
     */
    'otherInfo'?: string;
    /**
     * 
     * @type {PrivateProductData}
     * @memberof SaleData
     */
    'product'?: PrivateProductData;
}
/**
 * 
 * @export
 * @interface SlideshowData
 */
export interface SlideshowData {
    /**
     * ID of slideshow
     * @type {string}
     * @memberof SlideshowData
     */
    'id': string;
    /**
     * URL of hosted image
     * @type {string}
     * @memberof SlideshowData
     */
    'pictureUrl': string;
    /**
     * name of brand
     * @type {string}
     * @memberof SlideshowData
     */
    'urlToNavigateTo'?: string;
    /**
     * index of slideshow
     * @type {number}
     * @memberof SlideshowData
     */
    'index': number;
}
/**
 * 
 * @export
 * @interface UpdateProductRequest
 */
export interface UpdateProductRequest {
    /**
     * 
     * @type {PrivateProductData}
     * @memberof UpdateProductRequest
     */
    'productData': PrivateProductData;
}

/**
 * CmsApi - axios parameter creator
 * @export
 */
export const CmsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add pictures to product
         * @param {string} productId productId of the product to be deleted
         * @param {Array<File>} [files] A list of files to add to the product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPicturesToProduct: async (productId: string, files?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('addPicturesToProduct', 'productId', productId)
            const localVarPath = `/cms/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (files) {
                files.forEach((element) => {
                  localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new brand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cms/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cms/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new productType
         * @param {ProductTypeData} productTypeData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductType: async (productTypeData: ProductTypeData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productTypeData' is not null or undefined
            assertParamExists('createProductType', 'productTypeData', productTypeData)
            const localVarPath = `/cms/productTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productTypeData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new restocking
         * @param {RestockingData} restockingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRestocking: async (restockingData: RestockingData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restockingData' is not null or undefined
            assertParamExists('createRestocking', 'restockingData', restockingData)
            const localVarPath = `/cms/restocking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restockingData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new sale
         * @param {SaleData} saleData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSale: async (saleData: SaleData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleData' is not null or undefined
            assertParamExists('createSale', 'saleData', saleData)
            const localVarPath = `/cms/sale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saleData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new slideshow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSlideshow: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cms/slideshow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete brand information
         * @param {string} brandId brandId of the brand to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrand: async (brandId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('deleteBrand', 'brandId', brandId)
            const localVarPath = `/cms/brands/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a list of brands
         * @param {DeleteBrandsRequest} deleteBrandsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrands: async (deleteBrandsRequest: DeleteBrandsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteBrandsRequest' is not null or undefined
            assertParamExists('deleteBrands', 'deleteBrandsRequest', deleteBrandsRequest)
            const localVarPath = `/cms/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBrandsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete product information
         * @param {string} productId productId of the product to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteProduct', 'productId', productId)
            const localVarPath = `/cms/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a list of productTypes
         * @param {DeleteProductTypesRequest} deleteProductTypesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductTypes: async (deleteProductTypesRequest: DeleteProductTypesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteProductTypesRequest' is not null or undefined
            assertParamExists('deleteProductTypes', 'deleteProductTypesRequest', deleteProductTypesRequest)
            const localVarPath = `/cms/productTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteProductTypesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a list of products
         * @param {DeleteProductsRequest} deleteProductsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProducts: async (deleteProductsRequest: DeleteProductsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteProductsRequest' is not null or undefined
            assertParamExists('deleteProducts', 'deleteProductsRequest', deleteProductsRequest)
            const localVarPath = `/cms/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete restocking information
         * @param {string} restockingId RestockingId of the restocking to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestocking: async (restockingId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restockingId' is not null or undefined
            assertParamExists('deleteRestocking', 'restockingId', restockingId)
            const localVarPath = `/cms/restocking/{restockingId}`
                .replace(`{${"restockingId"}}`, encodeURIComponent(String(restockingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a list of restockings
         * @param {DeleteRestockingsRequest} deleteRestockingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestockings: async (deleteRestockingsRequest: DeleteRestockingsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteRestockingsRequest' is not null or undefined
            assertParamExists('deleteRestockings', 'deleteRestockingsRequest', deleteRestockingsRequest)
            const localVarPath = `/cms/restocking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteRestockingsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete sale information
         * @param {string} saleId saleId of the sale to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSale: async (saleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('deleteSale', 'saleId', saleId)
            const localVarPath = `/cms/sale/{saleId}`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a list of sales
         * @param {DeleteSalesRequest} deleteSalesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSales: async (deleteSalesRequest: DeleteSalesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteSalesRequest' is not null or undefined
            assertParamExists('deleteSales', 'deleteSalesRequest', deleteSalesRequest)
            const localVarPath = `/cms/sale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSalesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete slideshow information
         * @param {string} slideshowId slideshowId of the slideshow to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSlideshow: async (slideshowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slideshowId' is not null or undefined
            assertParamExists('deleteSlideshow', 'slideshowId', slideshowId)
            const localVarPath = `/cms/slideshow/{slideshowId}`
                .replace(`{${"slideshowId"}}`, encodeURIComponent(String(slideshowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a list of slideshows
         * @param {DeleteSlideshowsRequest} deleteSlideshowsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSlideshows: async (deleteSlideshowsRequest: DeleteSlideshowsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteSlideshowsRequest' is not null or undefined
            assertParamExists('deleteSlideshows', 'deleteSlideshowsRequest', deleteSlideshowsRequest)
            const localVarPath = `/cms/slideshow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteSlideshowsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get brands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cms/brands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get productTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cms/productTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cms/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get restockings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestocking: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cms/restocking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sales
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSales: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cms/sale`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update brand information
         * @param {string} brandId BrandId of the brand to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrand: async (brandId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('updateBrand', 'brandId', brandId)
            const localVarPath = `/cms/brands/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product information
         * @param {string} productId productId of the product to be fetched
         * @param {UpdateProductRequest} updateProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (productId: string, updateProductRequest: UpdateProductRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('updateProduct', 'productId', productId)
            // verify required parameter 'updateProductRequest' is not null or undefined
            assertParamExists('updateProduct', 'updateProductRequest', updateProductRequest)
            const localVarPath = `/cms/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update productType information
         * @param {string} productTypeId productTypeId of the sale to be fetched
         * @param {ProductTypeData} productTypeData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductType: async (productTypeId: string, productTypeData: ProductTypeData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productTypeId' is not null or undefined
            assertParamExists('updateProductType', 'productTypeId', productTypeId)
            // verify required parameter 'productTypeData' is not null or undefined
            assertParamExists('updateProductType', 'productTypeData', productTypeData)
            const localVarPath = `/cms/productTypes/{productTypeId}`
                .replace(`{${"productTypeId"}}`, encodeURIComponent(String(productTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productTypeData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update restocking information
         * @param {string} restockingId restockingId of the restocking to be fetched
         * @param {RestockingData} restockingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRestocking: async (restockingId: string, restockingData: RestockingData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restockingId' is not null or undefined
            assertParamExists('updateRestocking', 'restockingId', restockingId)
            // verify required parameter 'restockingData' is not null or undefined
            assertParamExists('updateRestocking', 'restockingData', restockingData)
            const localVarPath = `/cms/restocking/{restockingId}`
                .replace(`{${"restockingId"}}`, encodeURIComponent(String(restockingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restockingData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update sale information
         * @param {string} saleId saleId of the sale to be fetched
         * @param {SaleData} saleData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSale: async (saleId: string, saleData: SaleData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saleId' is not null or undefined
            assertParamExists('updateSale', 'saleId', saleId)
            // verify required parameter 'saleData' is not null or undefined
            assertParamExists('updateSale', 'saleData', saleData)
            const localVarPath = `/cms/sale/{saleId}`
                .replace(`{${"saleId"}}`, encodeURIComponent(String(saleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saleData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update slideshow information
         * @param {string} slideshowId slideshowId of the slideshow to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlideshow: async (slideshowId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slideshowId' is not null or undefined
            assertParamExists('updateSlideshow', 'slideshowId', slideshowId)
            const localVarPath = `/cms/slideshow/{slideshowId}`
                .replace(`{${"slideshowId"}}`, encodeURIComponent(String(slideshowId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CmsApi - functional programming interface
 * @export
 */
export const CmsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CmsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add pictures to product
         * @param {string} productId productId of the product to be deleted
         * @param {Array<File>} [files] A list of files to add to the product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPicturesToProduct(productId: string, files?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPicturesToProduct(productId, files, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.addPicturesToProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new brand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBrand(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateBrandData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBrand(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.createBrand']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateProductData1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.createProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new productType
         * @param {ProductTypeData} productTypeData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProductType(productTypeData: ProductTypeData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductTypeData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProductType(productTypeData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.createProductType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new restocking
         * @param {RestockingData} restockingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRestocking(restockingData: RestockingData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestockingData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRestocking(restockingData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.createRestocking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new sale
         * @param {SaleData} saleData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSale(saleData: SaleData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSale(saleData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.createSale']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Create a new slideshow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSlideshow(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlideshowData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSlideshow(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.createSlideshow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete brand information
         * @param {string} brandId brandId of the brand to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBrand(brandId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBrand(brandId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteBrand']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a list of brands
         * @param {DeleteBrandsRequest} deleteBrandsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBrands(deleteBrandsRequest: DeleteBrandsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBrands(deleteBrandsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteBrands']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete product information
         * @param {string} productId productId of the product to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a list of productTypes
         * @param {DeleteProductTypesRequest} deleteProductTypesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductTypes(deleteProductTypesRequest: DeleteProductTypesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductTypes(deleteProductTypesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteProductTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a list of products
         * @param {DeleteProductsRequest} deleteProductsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProducts(deleteProductsRequest: DeleteProductsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProducts(deleteProductsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete restocking information
         * @param {string} restockingId RestockingId of the restocking to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRestocking(restockingId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRestocking(restockingId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteRestocking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a list of restockings
         * @param {DeleteRestockingsRequest} deleteRestockingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRestockings(deleteRestockingsRequest: DeleteRestockingsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRestockings(deleteRestockingsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteRestockings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete sale information
         * @param {string} saleId saleId of the sale to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSale(saleId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSale(saleId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteSale']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a list of sales
         * @param {DeleteSalesRequest} deleteSalesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSales(deleteSalesRequest: DeleteSalesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSales(deleteSalesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteSales']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete slideshow information
         * @param {string} slideshowId slideshowId of the slideshow to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSlideshow(slideshowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSlideshow(slideshowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteSlideshow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete a list of slideshows
         * @param {DeleteSlideshowsRequest} deleteSlideshowsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSlideshows(deleteSlideshowsRequest: DeleteSlideshowsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSlideshows(deleteSlideshowsRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.deleteSlideshows']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get brands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrands(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrivateBrandData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrands(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.getBrands']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get productTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductTypeData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.getProductTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrivateProductData1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.getProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get restockings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestocking(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RestockingData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestocking(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.getRestocking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get sales
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSales(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SaleData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSales(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.getSales']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update brand information
         * @param {string} brandId BrandId of the brand to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBrand(brandId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateBrandData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBrand(brandId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.updateBrand']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update product information
         * @param {string} productId productId of the product to be fetched
         * @param {UpdateProductRequest} updateProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(productId: string, updateProductRequest: UpdateProductRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateProductData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(productId, updateProductRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.updateProduct']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update productType information
         * @param {string} productTypeId productTypeId of the sale to be fetched
         * @param {ProductTypeData} productTypeData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProductType(productTypeId: string, productTypeData: ProductTypeData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductTypeData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProductType(productTypeId, productTypeData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.updateProductType']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update restocking information
         * @param {string} restockingId restockingId of the restocking to be fetched
         * @param {RestockingData} restockingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRestocking(restockingId: string, restockingData: RestockingData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestockingData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRestocking(restockingId, restockingData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.updateRestocking']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update sale information
         * @param {string} saleId saleId of the sale to be fetched
         * @param {SaleData} saleData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSale(saleId: string, saleData: SaleData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSale(saleId, saleData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.updateSale']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update slideshow information
         * @param {string} slideshowId slideshowId of the slideshow to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSlideshow(slideshowId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlideshowData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSlideshow(slideshowId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CmsApi.updateSlideshow']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CmsApi - factory interface
 * @export
 */
export const CmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CmsApiFp(configuration)
    return {
        /**
         * 
         * @summary Add pictures to product
         * @param {string} productId productId of the product to be deleted
         * @param {Array<File>} [files] A list of files to add to the product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPicturesToProduct(productId: string, files?: Array<File>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.addPicturesToProduct(productId, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new brand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBrand(options?: any): AxiosPromise<PrivateBrandData> {
            return localVarFp.createBrand(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new product
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(options?: any): AxiosPromise<PrivateProductData1> {
            return localVarFp.createProduct(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new productType
         * @param {ProductTypeData} productTypeData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductType(productTypeData: ProductTypeData, options?: any): AxiosPromise<ProductTypeData> {
            return localVarFp.createProductType(productTypeData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new restocking
         * @param {RestockingData} restockingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRestocking(restockingData: RestockingData, options?: any): AxiosPromise<RestockingData> {
            return localVarFp.createRestocking(restockingData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new sale
         * @param {SaleData} saleData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSale(saleData: SaleData, options?: any): AxiosPromise<SaleData> {
            return localVarFp.createSale(saleData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new slideshow
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSlideshow(options?: any): AxiosPromise<SlideshowData> {
            return localVarFp.createSlideshow(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete brand information
         * @param {string} brandId brandId of the brand to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrand(brandId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBrand(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a list of brands
         * @param {DeleteBrandsRequest} deleteBrandsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBrands(deleteBrandsRequest: DeleteBrandsRequest, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.deleteBrands(deleteBrandsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete product information
         * @param {string} productId productId of the product to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(productId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a list of productTypes
         * @param {DeleteProductTypesRequest} deleteProductTypesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductTypes(deleteProductTypesRequest: DeleteProductTypesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProductTypes(deleteProductTypesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a list of products
         * @param {DeleteProductsRequest} deleteProductsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProducts(deleteProductsRequest: DeleteProductsRequest, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.deleteProducts(deleteProductsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete restocking information
         * @param {string} restockingId RestockingId of the restocking to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestocking(restockingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRestocking(restockingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a list of restockings
         * @param {DeleteRestockingsRequest} deleteRestockingsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestockings(deleteRestockingsRequest: DeleteRestockingsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRestockings(deleteRestockingsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete sale information
         * @param {string} saleId saleId of the sale to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSale(saleId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSale(saleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a list of sales
         * @param {DeleteSalesRequest} deleteSalesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSales(deleteSalesRequest: DeleteSalesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSales(deleteSalesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete slideshow information
         * @param {string} slideshowId slideshowId of the slideshow to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSlideshow(slideshowId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSlideshow(slideshowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a list of slideshows
         * @param {DeleteSlideshowsRequest} deleteSlideshowsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSlideshows(deleteSlideshowsRequest: DeleteSlideshowsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSlideshows(deleteSlideshowsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get brands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrands(options?: any): AxiosPromise<Array<PrivateBrandData>> {
            return localVarFp.getBrands(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get productTypes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTypes(options?: any): AxiosPromise<Array<ProductTypeData>> {
            return localVarFp.getProductTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(options?: any): AxiosPromise<Array<PrivateProductData1>> {
            return localVarFp.getProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get restockings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestocking(options?: any): AxiosPromise<Array<RestockingData>> {
            return localVarFp.getRestocking(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sales
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSales(options?: any): AxiosPromise<Array<SaleData>> {
            return localVarFp.getSales(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update brand information
         * @param {string} brandId BrandId of the brand to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBrand(brandId: string, options?: any): AxiosPromise<PrivateBrandData> {
            return localVarFp.updateBrand(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update product information
         * @param {string} productId productId of the product to be fetched
         * @param {UpdateProductRequest} updateProductRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(productId: string, updateProductRequest: UpdateProductRequest, options?: any): AxiosPromise<PrivateProductData> {
            return localVarFp.updateProduct(productId, updateProductRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update productType information
         * @param {string} productTypeId productTypeId of the sale to be fetched
         * @param {ProductTypeData} productTypeData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductType(productTypeId: string, productTypeData: ProductTypeData, options?: any): AxiosPromise<ProductTypeData> {
            return localVarFp.updateProductType(productTypeId, productTypeData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update restocking information
         * @param {string} restockingId restockingId of the restocking to be fetched
         * @param {RestockingData} restockingData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRestocking(restockingId: string, restockingData: RestockingData, options?: any): AxiosPromise<RestockingData> {
            return localVarFp.updateRestocking(restockingId, restockingData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update sale information
         * @param {string} saleId saleId of the sale to be fetched
         * @param {SaleData} saleData 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSale(saleId: string, saleData: SaleData, options?: any): AxiosPromise<SaleData> {
            return localVarFp.updateSale(saleId, saleData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update slideshow information
         * @param {string} slideshowId slideshowId of the slideshow to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSlideshow(slideshowId: string, options?: any): AxiosPromise<SlideshowData> {
            return localVarFp.updateSlideshow(slideshowId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CmsApi - object-oriented interface
 * @export
 * @class CmsApi
 * @extends {BaseAPI}
 */
export class CmsApi extends BaseAPI {
    /**
     * 
     * @summary Add pictures to product
     * @param {string} productId productId of the product to be deleted
     * @param {Array<File>} [files] A list of files to add to the product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public addPicturesToProduct(productId: string, files?: Array<File>, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).addPicturesToProduct(productId, files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new brand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public createBrand(options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).createBrand(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public createProduct(options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).createProduct(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new productType
     * @param {ProductTypeData} productTypeData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public createProductType(productTypeData: ProductTypeData, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).createProductType(productTypeData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new restocking
     * @param {RestockingData} restockingData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public createRestocking(restockingData: RestockingData, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).createRestocking(restockingData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new sale
     * @param {SaleData} saleData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public createSale(saleData: SaleData, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).createSale(saleData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new slideshow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public createSlideshow(options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).createSlideshow(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete brand information
     * @param {string} brandId brandId of the brand to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteBrand(brandId: string, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteBrand(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a list of brands
     * @param {DeleteBrandsRequest} deleteBrandsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteBrands(deleteBrandsRequest: DeleteBrandsRequest, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteBrands(deleteBrandsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete product information
     * @param {string} productId productId of the product to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteProduct(productId: string, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteProduct(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a list of productTypes
     * @param {DeleteProductTypesRequest} deleteProductTypesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteProductTypes(deleteProductTypesRequest: DeleteProductTypesRequest, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteProductTypes(deleteProductTypesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a list of products
     * @param {DeleteProductsRequest} deleteProductsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteProducts(deleteProductsRequest: DeleteProductsRequest, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteProducts(deleteProductsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete restocking information
     * @param {string} restockingId RestockingId of the restocking to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteRestocking(restockingId: string, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteRestocking(restockingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a list of restockings
     * @param {DeleteRestockingsRequest} deleteRestockingsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteRestockings(deleteRestockingsRequest: DeleteRestockingsRequest, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteRestockings(deleteRestockingsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete sale information
     * @param {string} saleId saleId of the sale to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteSale(saleId: string, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteSale(saleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a list of sales
     * @param {DeleteSalesRequest} deleteSalesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteSales(deleteSalesRequest: DeleteSalesRequest, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteSales(deleteSalesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete slideshow information
     * @param {string} slideshowId slideshowId of the slideshow to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteSlideshow(slideshowId: string, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteSlideshow(slideshowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a list of slideshows
     * @param {DeleteSlideshowsRequest} deleteSlideshowsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public deleteSlideshows(deleteSlideshowsRequest: DeleteSlideshowsRequest, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).deleteSlideshows(deleteSlideshowsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get brands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public getBrands(options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).getBrands(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get productTypes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public getProductTypes(options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).getProductTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public getProducts(options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).getProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get restockings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public getRestocking(options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).getRestocking(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sales
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public getSales(options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).getSales(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update brand information
     * @param {string} brandId BrandId of the brand to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public updateBrand(brandId: string, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).updateBrand(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update product information
     * @param {string} productId productId of the product to be fetched
     * @param {UpdateProductRequest} updateProductRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public updateProduct(productId: string, updateProductRequest: UpdateProductRequest, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).updateProduct(productId, updateProductRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update productType information
     * @param {string} productTypeId productTypeId of the sale to be fetched
     * @param {ProductTypeData} productTypeData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public updateProductType(productTypeId: string, productTypeData: ProductTypeData, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).updateProductType(productTypeId, productTypeData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update restocking information
     * @param {string} restockingId restockingId of the restocking to be fetched
     * @param {RestockingData} restockingData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public updateRestocking(restockingId: string, restockingData: RestockingData, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).updateRestocking(restockingId, restockingData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update sale information
     * @param {string} saleId saleId of the sale to be fetched
     * @param {SaleData} saleData 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public updateSale(saleId: string, saleData: SaleData, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).updateSale(saleId, saleData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update slideshow information
     * @param {string} slideshowId slideshowId of the slideshow to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CmsApi
     */
    public updateSlideshow(slideshowId: string, options?: RawAxiosRequestConfig) {
        return CmsApiFp(this.configuration).updateSlideshow(slideshowId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WebApi - axios parameter creator
 * @export
 */
export const WebApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get brand data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brandData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a product by id
         * @param {string} productId productId of the product to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: async (productId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductById', 'productId', productId)
            const localVarPath = `/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get product types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTypes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/productTypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all slides
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlideshowData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/slideshow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebApi - functional programming interface
 * @export
 */
export const WebApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProducts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebApi.getAllProducts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get brand data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrandData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebApi.getBrandData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get a product by id
         * @param {string} productId productId of the product to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductById(productId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductById(productId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebApi.getProductById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get product types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductTypes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductTypeData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductTypes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebApi.getProductTypes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List of all slides
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSlideshowData(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlideshowData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSlideshowData(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WebApi.getSlideshowData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WebApi - factory interface
 * @export
 */
export const WebApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebApiFp(configuration)
    return {
        /**
         * 
         * @summary List of all products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProducts(options?: any): AxiosPromise<Array<ProductData>> {
            return localVarFp.getAllProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get brand data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrandData(options?: any): AxiosPromise<Array<BrandData>> {
            return localVarFp.getBrandData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a product by id
         * @param {string} productId productId of the product to be fetched
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(productId: string, options?: any): AxiosPromise<ProductData> {
            return localVarFp.getProductById(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get product types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTypes(options?: any): AxiosPromise<Array<ProductTypeData>> {
            return localVarFp.getProductTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all slides
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSlideshowData(options?: any): AxiosPromise<Array<SlideshowData>> {
            return localVarFp.getSlideshowData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebApi - object-oriented interface
 * @export
 * @class WebApi
 * @extends {BaseAPI}
 */
export class WebApi extends BaseAPI {
    /**
     * 
     * @summary List of all products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebApi
     */
    public getAllProducts(options?: RawAxiosRequestConfig) {
        return WebApiFp(this.configuration).getAllProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get brand data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebApi
     */
    public getBrandData(options?: RawAxiosRequestConfig) {
        return WebApiFp(this.configuration).getBrandData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a product by id
     * @param {string} productId productId of the product to be fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebApi
     */
    public getProductById(productId: string, options?: RawAxiosRequestConfig) {
        return WebApiFp(this.configuration).getProductById(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get product types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebApi
     */
    public getProductTypes(options?: RawAxiosRequestConfig) {
        return WebApiFp(this.configuration).getProductTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of all slides
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebApi
     */
    public getSlideshowData(options?: RawAxiosRequestConfig) {
        return WebApiFp(this.configuration).getSlideshowData(options).then((request) => request(this.axios, this.basePath));
    }
}



