// src/components/TokenInput.tsx

import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { videomobileCmsApiKey } from "../../constants";

export const CmsTokenInput = () => {
  const [token, setToken] = useState<string>("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (token) {
      localStorage.setItem(videomobileCmsApiKey, token);
      navigate("/");
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Typography variant="h4" gutterBottom>
        Enter API Token
      </Typography>
      <TextField
        label="API Token"
        variant="outlined"
        value={token}
        onChange={(e) => setToken(e.target.value)}
        sx={{ mb: 2, width: "300px" }}
      />
      <Button variant="contained" onClick={handleSubmit}>
        Submit
      </Button>
    </Box>
  );
};
