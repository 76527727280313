import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../store/store";
import { Dispatch } from "react";
import { Box } from "@mui/material";
import { deleteSlideshows } from "../store/asyncThunk/productThunk";
import { useGetDialog } from "./useGetDialog";
import { useNavigate } from "react-router-dom";
import { useRequestDataIfNeeded } from "./useRequestDataIfNeeded";
import { routesList } from "../constants";

export const useSlideshowsDataGridInfo = () => {
  const slideshows = useAppSelector((state) => state.slideshows);
  const deleteDialog = useGetDialog();
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  useRequestDataIfNeeded(["slideshows"]);

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "Index",
      maxWidth: 60,
      flex: 1,
      type: "number",
      description: "Rradha e shfaqjes se slideshow ne website",
    },
    {
      field: "picture",
      headerName: "Brand Picture",
      flex: 1,
      description: "Fotoja e brandit",
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <img
            style={{ objectFit: "cover", height: "50px", userSelect: "none" }}
            src={params.row.pictureUrl}
            alt="Cannot load"
            draggable={false}
          />
        </Box>
      ),
    },
    {
      field: "urlToNavigateTo",
      headerName: "Destination URL",
      flex: 1,
      type: "string",
      description: "URL ku te con slideshow pasi e klikon",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <a
            href={params.row.urlToNavigateTo ?? "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params.row.urlToNavigateTo ?? "No URL"}
          </a>
        );
      },
    },
  ];

  const handleDeleteRows = (
    rows: GridRowSelectionModel,
    setSelectedRows: Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ) => {
    deleteDialog.update({
      title: `Are you sure you want to delete ${rows.length} Slideshows`,
      primaryButton: {
        label: "Yes",
        onClick: () => {
          dispatch(deleteSlideshows(rows as string[]));
          deleteDialog.close();
          setSelectedRows([]);
        },
      },
      secondaryButton: {
        label: "No",
        onClick: () => {
          deleteDialog.close();
        },
      },
    });
    deleteDialog.toggleDialog();
  };

  const handleAddInstance = () => {
    nav(routesList.addSlideshow);
  };

  const handleRowDoubleClick = (params: GridRowParams) => {
    nav(routesList.slideshows + `/${params.row.id}`);
  };

  return {
    gridProps: {
      rows: slideshows.list,
      columns: columns,
      handleDeleteRows: handleDeleteRows,
      handleAddInstance: handleAddInstance,
      handleRowDoubleClick: handleRowDoubleClick,
    },
    dialog: deleteDialog,
  };
};
