import { ProductTypeData } from "../../../packages/cmsapi";

export type ProductTypeAction =
  | { type: "SET_ProductType"; value: any }
  | { type: "SET_FIELD"; fieldName: keyof ProductTypeData; value: any }
  | { type: "RESET"; payload: ProductTypeData }
  | { type: "LOAD_ProductType"; payload: ProductTypeData };

export const ProductTypeReducer = (
  state: ProductTypeData,
  action: ProductTypeAction
): ProductTypeData => {
  switch (action.type) {
    case "SET_ProductType":
      return { ...action.value };
    case "SET_FIELD":
      return { ...state, [action.fieldName]: action.value };
    case "RESET":
      return action.payload;
    case "LOAD_ProductType":
      return action.payload;
    default:
      return state;
  }
};
