export const timeUntil = (date: string | undefined): number => {
  if (date === undefined) return 0;
  const givenDate = new Date(date);
  const currDate = new Date();

  const differenceInMs = givenDate.getTime() - currDate.getTime();

  return Math.floor(differenceInMs / 1000);
};

export const convertObjectToFormDataMultipleFiles = (
  obj: Object,
  files?: File[]
) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(`${key}`, String(item));
      });
    } else {
      formData.append(key, String(value));
    }
  }

  if (files) {
    files.forEach((file) => {
      formData.append("files", file);
    });
  }

  return formData;
};

export const convertObjectToFormDataSingleFile = (obj: Object, file?: File) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(`${key}`, String(item));
      });
    } else {
      formData.append(key, String(value));
    }
  }

  if (file) {
    formData.append("file", file);
  }

  return formData;
};

export const correctULTagFromQuill = (str: string) => {
  if (str) {
    let re = /(<ol><li data-list="bullet">)(.*?)(<\/ol>)/;
    let strArr = str.split(re);

    while (
      strArr.findIndex((ele) => ele === '<ol><li data-list="bullet">') !== -1
    ) {
      let indx = strArr.findIndex(
        (ele) => ele === '<ol><li data-list="bullet">'
      );
      if (indx) {
        strArr[indx] = '<ul><li data-list="bullet">';
        let endTagIndex = strArr.findIndex((ele) => ele === "</ol>");
        strArr[endTagIndex] = "</ul>";
      }
    }
    return strArr.join("");
  }
  return str;
};
