import { useSalesDataGridInfo } from "../../hooks/useSalesDataGridInfo";
import { DataTable } from "../Common";

export const SalesTable = () => {
  const { gridProps, dialog: deleteConfirmationDialog } =
    useSalesDataGridInfo();

  return (
    <>
      {deleteConfirmationDialog.render()}
      <DataTable {...gridProps} />
    </>
  );
};
