import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CategoryIcon from "@mui/icons-material/Category";
import TokenIcon from "@mui/icons-material/Token";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useState } from "react";
import { routesList } from "../../constants";

export const SideBar = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const location = useLocation(); // Get the current location

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <Sidebar
      collapsed={isCollapsed}
      transitionDuration={50}
      onMouseEnter={() => setIsCollapsed(false)}
      onMouseLeave={() => setIsCollapsed(true)}
      style={{
        height: "100%",
        display: "flex",
        alignContent: "center",
      }}
      width="161px"
    >
      <Menu
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <MenuItem
          icon={<HomeIcon />}
          component={<Link to={routesList.homepage} />}
          style={{
            backgroundColor: isActive(routesList.homepage)
              ? "#f0f0f0"
              : "transparent",
          }}
        >
          Home
        </MenuItem>

        <MenuItem
          icon={<InventoryIcon />}
          component={<Link to={routesList.products} />}
          style={{
            backgroundColor: isActive(routesList.products)
              ? "#f0f0f0"
              : "transparent",
          }}
        >
          Products
        </MenuItem>

        <MenuItem
          icon={<BrandingWatermarkIcon />}
          component={<Link to={routesList.brands} />}
          style={{
            backgroundColor: isActive(routesList.brands)
              ? "#f0f0f0"
              : "transparent",
          }}
        >
          Brands
        </MenuItem>

        <MenuItem
          icon={<SlideshowIcon />}
          component={<Link to={routesList.slideshows} />}
          style={{
            backgroundColor: isActive(routesList.slideshows)
              ? "#f0f0f0"
              : "transparent",
          }}
        >
          Slideshows
        </MenuItem>

        <MenuItem
          icon={<MonetizationOnIcon />}
          component={<Link to={routesList.sales} />}
          style={{
            backgroundColor: isActive(routesList.sales)
              ? "#f0f0f0"
              : "transparent",
          }}
        >
          Sales
        </MenuItem>

        <MenuItem
          icon={<Inventory2Icon />}
          component={<Link to={routesList.restocking} />}
          style={{
            backgroundColor: isActive(routesList.restocking)
              ? "#f0f0f0"
              : "transparent",
          }}
        >
          Restocking
        </MenuItem>

        <MenuItem
          icon={<CategoryIcon />}
          component={<Link to={routesList.productTypes} />}
          style={{
            backgroundColor: isActive(routesList.productTypes)
              ? "#f0f0f0"
              : "transparent",
          }}
        >
          Types
        </MenuItem>

        <MenuItem
          icon={<TokenIcon />}
          component={<Link to={routesList.insertToken} />}
          style={{
            backgroundColor: isActive(routesList.insertToken)
              ? "#f0f0f0"
              : "transparent",
          }}
        >
          Token
        </MenuItem>
      </Menu>
    </Sidebar>
  );
};
