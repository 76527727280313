import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import {
  DndContext,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { productReducer } from "./ProductReducer";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";
import { useRequestDataIfNeeded } from "../../../hooks/useRequestDataIfNeeded";
import { VirtualizedAutocomplete } from "../../Common";
import {
  addPicturesToProduct,
  updateProduct,
} from "../../../store/asyncThunk/productThunk";
import dayjs from "dayjs";
import { PrivateProductData } from "../../../packages/cmsapi";
import { ISortableImageProps, SortableImage } from "../../Common/SortableImage";
import { correctULTagFromQuill } from "../../../utils/utils";

const emptyProductSate = {
  id: "",
  name: "",
  picturesUrl: [],
  price: 0,
  tags: [],
  isHidden: false,
} as PrivateProductData;

export const EditProduct = () => {
  const { productId } = useParams();
  const products = useAppSelector((state) => state.products);
  const productInfoMap = useAppSelector((state) => state.productInfoMap);
  const brands = useAppSelector((state) => state.brands);
  const brandInfoMap = useAppSelector((state) => state.brandInfoMap);
  const productTypes = useAppSelector((state) => state.productTypes);
  const productTypesInfoMap = useAppSelector(
    (state) => state.productTypesInfoMap
  );

  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  const [productInfo, productDispatch] = useReducer(
    productReducer,
    productInfoMap[productId ?? ""] ?? emptyProductSate
  );

  const isSaveButtonDisabled = useMemo(() => {
    return productInfo.name ? false : true;
  }, [productInfo.name]);

  const virtualizedProductList = useMemo(() => {
    return products.list.filter(
      (product: PrivateProductData) =>
        !product.isDeleted && product.id !== productInfo.id
    );
  }, [productInfo.id, products.list]);

  const productTypesList = useMemo(() => {
    return productInfo.types?.map((typeId) => productTypesInfoMap[typeId]);
  }, [productInfo.types, productTypesInfoMap]);

  const [images, setImages] = React.useState<ISortableImageProps[] | undefined>(
    productInfo.picturesUrl.map((url, index) => {
      return {
        id: index.toString(),
        url: url,
      } as ISortableImageProps;
    }) || []
  );

  useRequestDataIfNeeded(["products", "brands", "productTypes"]);

  useEffect(() => {
    if (productInfoMap[productId ?? ""]) {
      productDispatch({
        type: "SET_PRODUCT",
        value: productInfoMap[productId ?? ""],
      });
      setImages(
        productInfo.picturesUrl.map((url, index) => {
          return { id: index.toString(), url: url } as ISortableImageProps;
        })
      );
    }
  }, [productInfoMap, productId, productInfo.picturesUrl]);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setImages((prevImages) => {
        if (prevImages) {
          const oldIndex = prevImages.findIndex(
            (image) => image.id === active.id
          );
          const newIndex = prevImages.findIndex(
            (image) => image.id === over.id
          );

          return arrayMove(prevImages, oldIndex, newIndex);
        }
      });
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && productInfo.id) {
      dispatch(
        addPicturesToProduct({
          productId: productInfo.id,
          files: Array.from(files),
        })
      );
    }
  };

  const handleImageDelete = (id: string) => {
    setImages(images?.filter((image) => image.id !== id));
  };

  const pictureSection = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={images ? images.map((image) => image.id) : []}
            strategy={rectSortingStrategy}
          >
            <Box
              sx={{
                width: 600,
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
                gap: 1,
                justifyContent: "center",
                justifyItems: "center",
                overflow: "hidden",
              }}
            >
              {images?.map((image) => (
                <SortableImage
                  key={image.id}
                  id={image.id}
                  url={image.url}
                  onDelete={handleImageDelete}
                />
              ))}
            </Box>
          </SortableContext>
        </DndContext>

        <Box sx={{ margin: 5 }}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="upload-image"
            multiple
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              Upload Images
            </Button>
          </label>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Edit Product
      </Typography>

      {/* Indication for Deleted Product */}
      {productInfo.isDeleted && (
        <Typography variant="h6" color="error" gutterBottom>
          This product has been deleted.
        </Typography>
      )}

      <Grid2 container spacing={2} justifyContent={"center"}>
        {/* Images */}
        <Grid2
          size={{ xs: 12 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            overflowX: "auto",
          }}
        >
          {pictureSection()}
        </Grid2>

        {/* Product Name */}
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            label="Product Name"
            variant="outlined"
            fullWidth
            required
            value={productInfo.name}
            onChange={(e) =>
              productDispatch({
                type: "SET_FIELD",
                fieldName: "name",
                value: e.currentTarget.value,
              })
            }
          />
        </Grid2>

        {/* Price */}
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            label="Price"
            type="number"
            variant="outlined"
            fullWidth
            required
            value={productInfo.price}
            slotProps={{ htmlInput: { min: 0 } }}
            onKeyDown={(event) => {
              if (event?.key === "-" || event?.key === "+") {
                event.preventDefault();
              }
            }}
            onChange={(e) =>
              productDispatch({
                type: "SET_FIELD",
                fieldName: "price",
                value: Number(e.currentTarget.value),
              })
            }
          />
        </Grid2>

        {/* Checkbox for isHidden */}
        <Grid2 size={{ xs: 12 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={productInfo.isHidden}
                onChange={(e) => {
                  productDispatch({
                    type: "SET_FIELD",
                    fieldName: "isHidden",
                    value: e.target.checked,
                  });
                }}
                color="primary"
              />
            }
            label="Hide Product"
          />
        </Grid2>

        {/* Brand */}
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <FormControl fullWidth>
            <InputLabel id="BrandLabel">Brand</InputLabel>
            <Select
              value={brandInfoMap[productInfo.brandId ?? ""]?.id ?? "NoBrand"}
              label={"Brand"}
              labelId="BrandLabel"
              fullWidth
              onChange={(e) => {
                productDispatch({
                  type: "SET_FIELD",
                  fieldName: "brandId",
                  value: e.target.value,
                });
              }}
            >
              <MenuItem value={"NoBrand"}>No Brand</MenuItem>
              {brands.list
                .filter((brand) => !brand.isDeleted)
                .map((brand) => {
                  return (
                    <MenuItem key={brand.id} value={brand.id}>
                      {brand.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid2>

        {/* Stock */}
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            label="Stock"
            type="number"
            variant="outlined"
            fullWidth
            required
            disabled
            helperText={"You can only edit stock through sales and restocking"}
            value={productInfo.stock ?? ""}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 6 }}>
          <DatePicker
            sx={{ width: "100%" }}
            label="Discount Until"
            format="DD-MM-YYYY" // Setting the desired date format
            value={dayjs(productInfo.discountUntil, "DD-MM-YYYY")}
            onChange={(newValue) => {
              productDispatch({
                type: "SET_FIELD",
                fieldName: "discountUntil",
                value: newValue?.format("DD-MM-YYYY") ?? "",
              });
            }}
          />
        </Grid2>

        {/* Discount Price */}
        <Grid2 size={{ xs: 12, sm: 6 }}>
          <TextField
            label="Discount Price"
            type="number"
            variant="outlined"
            fullWidth
            value={productInfo.discountPrice || ""}
            onChange={(e) =>
              productDispatch({
                type: "SET_FIELD",
                fieldName: "discountPrice",
                value: e.currentTarget.value,
              })
            }
          />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 6 }}>
          <DatePicker
            label="New product until"
            format="DD-MM-YYYY" // Setting the desired date format
            value={dayjs(productInfo.newProductUntil, "DD-MM-YYYY")}
            sx={{ width: "100%" }}
            onChange={(newValue) => {
              productDispatch({
                type: "SET_FIELD",
                fieldName: "newProductUntil",
                value: newValue?.format("DD-MM-YYYY") ?? "",
              });
            }}
          />
        </Grid2>

        <Grid2 size={{ xs: 12, sm: 6 }}>
          <DatePicker
            sx={{ width: "100%" }}
            label="Coming soon until"
            format="DD-MM-YYYY" // Setting the desired date format
            value={dayjs(productInfo.comingSoonUntil, "DD-MM-YYYY")}
            onChange={(newValue) => {
              productDispatch({
                type: "SET_FIELD",
                fieldName: "comingSoonUntil",
                value: newValue?.format("DD-MM-YYYY") ?? "",
              });
            }}
          />
        </Grid2>

        {/* suggestions */}
        <Grid2 size={{ xs: 12 }}>
          <VirtualizedAutocomplete
            options={virtualizedProductList}
            productInfoMap={productInfoMap}
            setSelected={(suggestions) => {
              productDispatch({
                type: "SET_FIELD",
                fieldName: "suggestions",
                value: suggestions.map((suggestion) => suggestion.id),
              });
            }}
            selected={productInfo.suggestions ?? []}
          />
        </Grid2>

        {/* productTypes */}
        <Grid2 size={{ xs: 12 }}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={productTypes.list}
            getOptionLabel={(option) => {
              return option?.name;
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Product Types"
                placeholder="Product Types"
              />
            )}
            value={productTypesList ?? []}
            onChange={(_, newVal) => {
              productDispatch({
                type: "SET_FIELD",
                fieldName: "types",
                value: newVal.map((productType) => productType.id),
              });
            }}
          />
        </Grid2>
        {/* Description (Rich Text Editor) */}
        <Grid2 size={{ xs: 12 }}>
          <Typography variant="subtitle1" gutterBottom>
            Description
          </Typography>
          <ReactQuill
            theme="snow"
            value={productInfo.description}
            style={{ height: "200px", marginBottom: "50px" }}
            onChange={(e) =>
              productDispatch({
                type: "SET_FIELD",
                fieldName: "description",
                value: e,
              })
            }
          />
        </Grid2>
        <Grid2 container spacing={2} size={{ xs: 12 }}>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => {
                nav(-1);
              }}
            >
              Cancel
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSaveButtonDisabled}
              onClick={() => {
                dispatch(
                  updateProduct({
                    productId: productInfo.id,
                    productData: {
                      ...productInfo,
                      picturesUrl: images?.map((image) => image.url) ?? [],
                      description: productInfo.description
                        ? correctULTagFromQuill(productInfo.description)
                        : undefined,
                    },
                  })
                );
                nav(-1);
              }}
            >
              Save
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};
