import { useProductsDataGridInfo } from "../../hooks/useProductsDataGridInfo";
import { DataTable } from "../Common";

export const ProductsTable = () => {
  const { gridProps, dialog: deleteConfirmationDialog } =
    useProductsDataGridInfo();

  return (
    <>
      {deleteConfirmationDialog.render()}
      <DataTable {...gridProps} />
    </>
  );
};
