import React from "react";
import { ProductsTable } from "../ProductsTable";
import { Box } from "@mui/material";

export const Homepage = () => {
  //TODO: add statistics
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Coming soon
    </Box>
  );
};
