import { RestockingData } from "../../../packages/cmsapi";

export type RestockingAction =
  | { type: "SET_RESTOCKING"; value: any }
  | { type: "SET_FIELD"; fieldName: keyof RestockingData; value: any }
  | { type: "RESET"; payload: RestockingData }
  | { type: "LOAD_RESTOCKING"; payload: RestockingData };

export const RestockingReducer = (
  state: RestockingData,
  action: RestockingAction
): RestockingData => {
  switch (action.type) {
    case "SET_RESTOCKING":
      return { ...action.value };
    case "SET_FIELD":
      return { ...state, [action.fieldName]: action.value };
    case "RESET":
      return action.payload;
    case "LOAD_RESTOCKING":
      return action.payload;
    default:
      return state;
  }
};
