import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import { Box } from "@mui/material";
import { Homepage } from "./components";
import { ProductsTable } from "./components/ProductsTable";
import { BrandsTable } from "./components/BrandsTable";
import { SlideshowsTable } from "./components/SlideshowsTable";
import { SalesTable } from "./components/SalesTable";
import { RestockingTable } from "./components/RestockingTable";
import { SideBar } from "./components/Common";
import {
  EditProduct,
  AddProduct,
  AddBrand,
  EditBrand,
  AddSlideshow,
  EditSlideshow,
  EditSales,
  AddSales,
  AddRestocking,
  EditRestocking,
} from "./components/AddOrEditInstance";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { routesList, videomobileCmsApiKey } from "./constants";
import { ProductTypesTable } from "./components/ProductTypesTable";
import {
  AddProductType,
  EditProductType,
} from "./components/AddOrEditInstance/ProductType";
import { CmsTokenInput } from "./components/CmsTokenInput/CmsTokenInput";

interface IProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: IProtectedRouteProps) => {
  const token = localStorage.getItem(videomobileCmsApiKey);

  if (!token) {
    return <Navigate to={routesList.insertToken} />;
  }

  return <>{children}</>;
};

function AppContent() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display={"flex"} sx={{ height: "100vh" }}>
        <SideBar />
        <Box
          sx={{
            flexGrow: "1",
            height: "100%",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <Routes>
            <Route path={routesList.insertToken} element={<CmsTokenInput />} />
            <Route
              index
              element={
                <ProtectedRoute>
                  <Homepage />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <Homepage />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.homepage}
              element={
                <ProtectedRoute>
                  <Homepage />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.products}
              element={
                <ProtectedRoute>
                  <ProductsTable />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.editProduct}
              element={
                <ProtectedRoute>
                  <EditProduct />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.addProduct}
              element={
                <ProtectedRoute>
                  <AddProduct />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.brands}
              element={
                <ProtectedRoute>
                  <BrandsTable />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.editBrand}
              element={
                <ProtectedRoute>
                  <EditBrand />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.addBrand}
              element={
                <ProtectedRoute>
                  <AddBrand />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.sales}
              element={
                <ProtectedRoute>
                  <SalesTable />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.editSale}
              element={
                <ProtectedRoute>
                  <EditSales />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.addSale}
              element={
                <ProtectedRoute>
                  <AddSales />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.restocking}
              element={
                <ProtectedRoute>
                  <RestockingTable />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.addRestocking}
              element={
                <ProtectedRoute>
                  <AddRestocking />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.editRestocking}
              element={
                <ProtectedRoute>
                  <EditRestocking />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.slideshows}
              element={
                <ProtectedRoute>
                  <SlideshowsTable />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.editSlideshow}
              element={
                <ProtectedRoute>
                  <EditSlideshow />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.addSlideshow}
              element={
                <ProtectedRoute>
                  <AddSlideshow />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.productTypes}
              element={
                <ProtectedRoute>
                  <ProductTypesTable />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.addProductType}
              element={
                <ProtectedRoute>
                  <AddProductType />
                </ProtectedRoute>
              }
            />
            <Route
              path={routesList.editProductType}
              element={
                <ProtectedRoute>
                  <EditProductType />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Box>
      </Box>
    </LocalizationProvider> // </Container>
  );
}

export default AppContent;
