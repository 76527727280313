import { useSortable } from "@dnd-kit/sortable";
import { Card, IconButton, CardMedia } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { CSS } from "@dnd-kit/utilities";

export interface ISortableImageProps {
  id: string;
  url: string;
  onDelete: (id: string) => void;
  width?: number;
  height?: number;
  file?: File;
}

export const SortableImage = ({
  id,
  url,
  onDelete,
  width = 150,
  height = 150,
  file = undefined,
}: ISortableImageProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card
      style={style}
      {...attributes}
      {...listeners}
      sx={{ width: width, height: height, position: "relative" }}
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 5,
          right: 5,
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          borderRadius: "50%",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          },
        }}
        onClick={() => {
          onDelete(id);
        }}
      >
        <DeleteIcon />
      </IconButton>
      <CardMedia
        component="img"
        ref={setNodeRef}
        image={url}
        alt={`Image ${id}`}
        sx={{ width: "100%", height: "100%" }}
      />
    </Card>
  );
};
