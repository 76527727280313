import { SaleData } from "../../../packages/cmsapi";

export type SalesAction =
  | { type: "SET_SALES"; value: any }
  | { type: "SET_FIELD"; fieldName: keyof SaleData; value: any }
  | { type: "RESET"; payload: SaleData }
  | { type: "LOAD_SALES"; payload: SaleData };

export const SalesReducer = (
  state: SaleData,
  action: SalesAction
): SaleData => {
  switch (action.type) {
    case "SET_SALES":
      return { ...action.value };
    case "SET_FIELD":
      return { ...state, [action.fieldName]: action.value };
    case "RESET":
      return action.payload;
    case "LOAD_SALES":
      return action.payload;
    default:
      return state;
  }
};
