import {
  PrivateProductData,
  PrivateBrandData,
  SaleData,
  RestockingData,
  SlideshowData,
} from "../packages/cmsapi";

export type DataTableRowType =
  | PrivateProductData
  | PrivateBrandData
  | SaleData
  | RestockingData
  | SlideshowData;

export const videomobileCmsApiKey = "videomobileCmsApiKey";
