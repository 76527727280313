import { createTheme } from "@mui/material";

export const Colors = {
  // Primary theme colors
  themePrimary: "#f79f20",
  themeLighterAlt: "#fffaf4",
  themeLighter: "#ffe5c9",
  themeLight: "#ffd09a",
  themeTertiary: "#ffa352",
  themeSecondary: "#ff7b23",
  themeDarkAlt: "#e66f1d",
  themeDark: "#c25e19",
  themeDarker: "#8f4412",

  // Neutral colors
  neutralLighterAlt: "#f8f8f8",
  neutralLighter: "#f4f4f4",
  neutralLight: "#eaeaea",
  neutralQuaternaryAlt: "#dadada",
  neutralQuaternary: "#d0d0d0",
  neutralTertiaryAlt: "#c8c8c8",
  neutralTertiary: "#a8a8a8",
  neutralSecondary: "#767676",
  neutralSecondaryAlt: "#6c6c6c",
  neutralPrimaryAlt: "#3c3c3c",
  neutralPrimary: "#333333",
  neutralDark: "#212121",
  black: "#000000",
  white: "#ffffff",

  // Hover primary color
  hoverPrimary: "#e66f1d",

  // Main border color
  mainBorderColor: "#c25e19",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#F5A01B", // Warm Orange
    },
    secondary: {
      main: "#1B6FF5", // Bright Blue
    },
    error: {
      main: "#F55D1B", // Warm Red
    },
    warning: {
      main: "#F5C71B", // Warm Yellow
    },
    info: {
      main: "#1B1BF5", // Vibrant Blue
    },
    success: {
      main: "#1BF5A0", // Bright Teal
    },
    background: {
      default: "#F5F5F5", // Light Gray
      paper: "#FFFFFF", // White for paper elements
    },
    text: {
      primary: "#333333", // Dark Gray for primary text
      secondary: "#CCCCCC", // Medium Gray for secondary text
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
        disableGutters: true,
      },
    },
    MuiCardMedia: {
      defaultProps: {
        draggable: false,
      },
    },
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body: {
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            // backgroundColor: "#2b2b2b", // Scrollbar background
            height: "12px", // Scrollbar height for horizontal scroll
            width: "12px", // Scrollbar width for vertical scroll
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b", // Scrollbar thumb color
            minHeight: 24,
            border: `3px solid ${theme.palette.background.paper}`, // Adds padding around the thumb
          },
        },
      }),
    },
  },
  typography: {},
});
