import React, { useReducer, useState } from "react";
import { SlideshowData } from "../../../packages/cmsapi";
import { Box, Button, Grid2, TextField, Typography } from "@mui/material";
import { ISortableImageProps, SortableImage } from "../../Common/SortableImage";
import { useAppDispatch } from "../../../store/store";
import { createSlideshow } from "../../../store/asyncThunk/productThunk";
import { useNavigate } from "react-router-dom";
import { SlideshowReducer } from "./SlideshowReducer";

const emptySlideshowState = {
  urlToNavigateTo: "",
} as SlideshowData;

export const AddSlideshow = () => {
  const [slideshowInfo, slideshowDispatch] = useReducer(
    SlideshowReducer,
    emptySlideshowState
  );
  const [image, setImage] = useState<ISortableImageProps>();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const nav = useNavigate();
  const dispatch = useAppDispatch();

  const handleImageDelete = () => {
    setImage(undefined);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const uploadedImage = {
        id: "uploaded image",
        url: URL.createObjectURL(file),
      } as ISortableImageProps;

      setImage(uploadedImage);
      setUploadedFile(file);
    }
  };

  const pictureSection = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {image && (
          <SortableImage
            key={image.id}
            id={image.id}
            url={image.url}
            onDelete={handleImageDelete}
            width={210}
            height={90}
          />
        )}
        <Box sx={{ margin: 5 }}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="upload-image"
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              Upload Image
            </Button>
          </label>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Add Slideshow
      </Typography>
      <Grid2 container spacing={2} justifyContent={"center"}>
        <Grid2
          size={{ xs: 12 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            overflowX: "auto",
          }}
        >
          {pictureSection()}
        </Grid2>

        {/* Index */}
        <Grid2 size={{ xs: 12 }}>
          <TextField
            label="Index"
            type="number"
            variant="outlined"
            fullWidth
            value={slideshowInfo.index || ""}
            onChange={(e) => {
              slideshowDispatch({
                type: "SET_FIELD",
                fieldName: "index",
                value: e.target.value,
              });
            }}
          />
        </Grid2>

        {/* Slideshow urlToNavigateTo */}
        <Grid2 size={{ xs: 12 }}>
          <TextField
            label="Url to navigate to"
            variant="outlined"
            fullWidth
            value={slideshowInfo.urlToNavigateTo}
            required
            onChange={(e) =>
              slideshowDispatch({
                type: "SET_FIELD",
                fieldName: "urlToNavigateTo",
                value: e.currentTarget.value,
              })
            }
          />
        </Grid2>

        <Grid2 container spacing={2} size={{ xs: 12 }}>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => {
                nav(-1);
              }}
            >
              Cancel
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch(
                  createSlideshow({
                    slideshowData: slideshowInfo,
                    file: uploadedFile,
                  })
                );
                nav(-1);
              }}
            >
              Save
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};
