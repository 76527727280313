export enum routesList {
  homepage = "/home",
  products = "/products",
  editProduct = "/products/:productId",
  addProduct = "/products/addProduct",
  brands = "/brands",
  editBrand = "/brands/:brandId",
  addBrand = "/brands/addBrand",
  sales = "/sales",
  editSale = "/sales/:saleId",
  addSale = "/sales/addSale",
  restocking = "/restocking",
  editRestocking = "/restocking/:restockingId",
  addRestocking = "/restocking/addRestocking",
  slideshows = "/slideshows",
  editSlideshow = "/slideshows/:slideshowId",
  addSlideshow = "/slideshows/addSlideshow",
  productTypes = "/productTypes",
  editProductType = "/productTypes/:productTypeId",
  addProductType = "/productTypes/addProductType",
  insertToken = "/insertToken",
}

export enum Brand {
  NoBrand = "NoBrand",
}
