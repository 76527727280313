import { useSlideshowsDataGridInfo } from "../../hooks/useSlideshowsDataGridInfo";
import { DataTable } from "../Common";

export const SlideshowsTable = () => {
  const { gridProps, dialog: deleteConfirmationDialog } =
    useSlideshowsDataGridInfo();

  return (
    <>
      {deleteConfirmationDialog.render()}
      <DataTable {...gridProps} />
    </>
  );
};
