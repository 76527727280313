import { useProductTypesDataGridInfo } from "../../hooks/useProductTypesDataGridInfo";
import { DataTable } from "../Common";

export const ProductTypesTable = () => {
  const { gridProps, dialog: deleteConfirmationDialog } =
    useProductTypesDataGridInfo();
  return (
    <>
      {deleteConfirmationDialog.render()}
      <DataTable {...gridProps} />
    </>
  );
};
