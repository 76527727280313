import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList, ListChildComponentProps } from "react-window";
import Typography from "@mui/material/Typography";
import { PrivateProductData } from "../../packages/cmsapi";
import { IProductInfoMap } from "../../store/slices/productSlice";

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  const { key, ...optionProps } = dataSet[0];

  return (
    <Typography
      key={key}
      component="li"
      {...optionProps}
      noWrap
      style={inlineStyle}
    >
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactElement<any>[] = [];
  (children as React.ReactElement<any>[]).forEach(
    (
      item: React.ReactElement<any> & { children?: React.ReactElement<any>[] }
    ) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactElement<any>) => {
    if (child.hasOwnProperty("group")) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

interface IVirtualizedAutocomplete {
  options: PrivateProductData[];
  productInfoMap: IProductInfoMap;
  setSelected: (suggestions: PrivateProductData[]) => void;
  selected: string[];
  isMultiple?: boolean;
  label?: string;
  isDisabled?: boolean;
  helperText?: string;
}
export const VirtualizedAutocomplete = ({
  options,
  productInfoMap,
  setSelected,
  selected,
  isMultiple = true,
  label = "Suggestions",
  isDisabled = false,
  helperText = "",
}: IVirtualizedAutocomplete) => {
  const autoCompleteValue = React.useMemo(() => {
    if (isMultiple) {
      return selected
        .map((selection) => productInfoMap[selection])
        .filter(Boolean);
    } else {
      const singleSelection = selected[0];
      return singleSelection ? productInfoMap[singleSelection] : null;
    }
  }, [selected, productInfoMap, isMultiple]);

  return (
    <Autocomplete
      multiple={isMultiple}
      disabled={isDisabled}
      filterSelectedOptions
      sx={{ width: "100%" }}
      disableListWrap
      options={options}
      getOptionLabel={(option) => {
        return option.name ? option.name : "";
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} helperText={helperText} />
      )}
      renderOption={(props, option, state) =>
        [props, option.name, state.index] as React.ReactNode
      }
      slots={{
        popper: StyledPopper,
        listbox: ListboxComponent,
      }}
      // value={selected.map((selection) => productInfoMap[selection]) ?? []}
      value={autoCompleteValue ?? (isMultiple ? [] : null)}
      onChange={(_, newVal) => {
        // setSelected(newVal);

        // NEW SOLUTION
        if (isMultiple) {
          if (Array.isArray(newVal)) {
            setSelected(newVal);
          } else {
            setSelected([]);
          }
        } else {
          // For single selection
          if (!Array.isArray(newVal)) {
            if (newVal) {
              setSelected([newVal]);
            } else {
              setSelected([]);
            }
          }
        }
      }}
    />
  );
};
