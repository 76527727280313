import {
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../store/store";
import { Dispatch } from "react";
import { PrivateProductData } from "../packages/cmsapi";
import { Box, Link, Tooltip } from "@mui/material";
import { deleteProducts } from "../store/asyncThunk/productThunk";
import { useGetDialog } from "./useGetDialog";
import { useNavigate } from "react-router-dom";
import { useRequestDataIfNeeded } from "./useRequestDataIfNeeded";
import { DisplayHtmlText } from "../components/Common";
import { routesList, Brand } from "../constants";

export const useProductsDataGridInfo = () => {
  const products = useAppSelector((state) => state.products);
  const productsInfoMap = useAppSelector((state) => state.productInfoMap);
  const productTypesInfoMap = useAppSelector(
    (state) => state.productTypesInfoMap
  );
  const brandInfoMap = useAppSelector((state) => state.brandInfoMap);
  const deleteDialog = useGetDialog();
  const dispatch = useAppDispatch();
  const nav = useNavigate();

  useRequestDataIfNeeded(["products", "brands", "productTypes"]);

  const columns: GridColDef[] = [
    {
      field: "picture",
      headerName: "Picture",
      minWidth: 60,
      description: "Fotoja e produktit",
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img
              style={{ objectFit: "cover", height: "50px", userSelect: "none" }}
              src={params.row.picturesUrl[0]}
              alt={params.row.picturesUrl[0] ? "Cannot load" : "No picture"}
              draggable={false}
            />
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 8,
      description: "Emri i produktit",
      renderCell: (params) => (
        <Tooltip title={params.row.name} arrow>
          <Link
            underline="hover"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              nav(routesList.products + `/${params.row.id}`, {
                state: { ...(params.row as PrivateProductData) },
              });
            }}
          >
            {params.row.name}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: "brandId",
      headerName: "Brand",
      minWidth: 80,
      flex: 7,
      valueGetter: (value) => {
        // return value ? value : Brand.NoBrand;
        return brandInfoMap[value]?.name ?? Brand.NoBrand;
      },
      description: "Brandi i produktit",
      renderCell: (params) => (
        <Tooltip title={brandInfoMap[params.row.brandId]?.name ?? "No Brand"}>
          <Link
            underline="hover"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              if (brandInfoMap[params.row.brandId]) {
                nav(routesList.brands + `/${params.row.brandId}`);
              }
            }}
          >
            {brandInfoMap[params.row.brandId]?.name ?? "No Brand"}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      minWidth: 80,
      flex: 6,
      align: "left",
      headerAlign: "left",
      description: "Cmimi i produktit",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.price}>
            <span>{params.row.price}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "discountPrice",
      headerName: "Discount Price",
      type: "number",
      minWidth: 80,
      flex: 5,
      align: "left",
      headerAlign: "left",
      description: "Cmimi i produktit pas uljes",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.discountPrice}>
            <span>{params.row.discountPrice}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "discountUntil",
      headerName: "Discount Until",
      type: "date",
      minWidth: 100,
      flex: 4,
      align: "left",
      headerAlign: "left",
      valueGetter: (value: string) => {
        // return new Date(value);
        const dateStr = value;
        if (dateStr) {
          const [day, month, year] = dateStr.split("-").map(Number);
          return new Date(year, month - 1, day);
        }
        return null;
      },
      description: "Data ne te cilen mbaron oferta e produktit",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.discountUntil}>
            <span>{params.row.discountUntil}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "stock",
      headerName: "Stock",
      type: "number",
      minWidth: 50,
      flex: 3,
      align: "left",
      headerAlign: "left",
      description: "Sa produkt kemi ne gjendje",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.stock}>
            <span>{params.row.stock}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "comingSoonUntil",
      headerName: "Coming Soon Until",
      type: "date",
      minWidth: 120,
      flex: 2,
      valueGetter: (value: string) => {
        // return new Date(value);
        const dateStr = value;
        if (dateStr) {
          const [day, month, year] = dateStr.split("-").map(Number);
          return new Date(year, month - 1, day);
        }
        return null;
      },
      description: "Data ne te cilen produkti behet pjese e videomobile",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.comingSoonUntil}>
            <span>{params.row.comingSoonUntil}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "newProductUntil",
      headerName: "New Product Until",
      type: "date",
      minWidth: 120,
      flex: 1,
      valueGetter: (value: string) => {
        // return new Date(value);
        const dateStr = value;
        if (dateStr) {
          const [day, month, year] = dateStr.split("-").map(Number);
          return new Date(year, month - 1, day);
        }
        return null;
      },
      description: "Data ne te cilen produkti nuk konsiderohet me si i ri",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.newProductUntil}>
            <span>{params.row.newProductUntil}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "suggestions",
      headerName: "Suggestions",
      minWidth: 120,
      flex: 1,
      description: "Sugjerimet per kete produkt",
      valueGetter: (params: string[]) => {
        return params.map((productId) => productsInfoMap[productId]?.name);
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params.value.join(", ")} arrow>
            <span>{params.value.join(", ")}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "types",
      headerName: "Types",
      minWidth: 120,
      flex: 1,
      description: "Llojet e ketij produkti",
      valueGetter: (params: string[]) => {
        return params.map((typeId) => productTypesInfoMap[typeId]?.name);
      },
      renderCell: (params) => {
        return (
          <Tooltip title={params.value.join(", ")} arrow>
            <span>{params.value.join(", ")}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 380,
      flex: 1,
      description: "Pershkrimi i produktit",
      renderCell: (params) => (
        <Tooltip
          title={<DisplayHtmlText description={params.value || ""} />}
          arrow
          placement="bottom"
          sx={{ maxWidth: 10 }}
        >
          <span
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <DisplayHtmlText description={params.value || ""} />
          </span>
        </Tooltip>
      ),
    },
  ];

  const handleDeleteRows = (
    rows: GridRowSelectionModel,
    setSelectedRows: Dispatch<React.SetStateAction<GridRowSelectionModel>>
  ) => {
    deleteDialog.update({
      title: "Are you sure you want to delete the product/s?",
      primaryButton: {
        label: "Yes",
        onClick: () => {
          dispatch(deleteProducts(rows as string[]));
          deleteDialog.close();
          setSelectedRows([]);
        },
      },
      secondaryButton: {
        label: "No",
        onClick: () => {
          deleteDialog.close();
        },
      },
    });
    deleteDialog.toggleDialog();
  };

  const handleAddInstance = () => {
    nav(routesList.addProduct);
  };

  const handleRowDoubleClick = (params: GridRowParams) => {
    nav(routesList.products + `/${params.row.id}`);
  };

  return {
    gridProps: {
      rows: products.list,
      columns: columns,
      handleDeleteRows: handleDeleteRows,
      handleAddInstance: handleAddInstance,
      handleRowDoubleClick: handleRowDoubleClick,
    },
    dialog: deleteDialog,
  };
};
