import React, { useEffect, useReducer, useState } from "react";
import { ProductTypeData } from "../../../packages/cmsapi";
import { ProductTypeReducer } from "./ProductTypeReducer";
import { Box, Button, Grid2, TextField, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { updateProductType } from "../../../store/asyncThunk/productThunk";
import { useNavigate, useParams } from "react-router-dom";

const emptyProductTypeState = { name: "" } as ProductTypeData;

export const EditProductType = () => {
  const { productTypeId } = useParams();
  const productTypesInfoMap = useAppSelector(
    (state) => state.productTypesInfoMap
  );

  const [productTypeInfo, productTypeDispatch] = useReducer(
    ProductTypeReducer,
    emptyProductTypeState
  );

  const nav = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (productTypesInfoMap[productTypeId ?? ""]) {
      productTypeDispatch({
        type: "SET_ProductType",
        value: productTypesInfoMap[productTypeId ?? ""],
      });
    }
  }, [productTypeId, productTypesInfoMap]);

  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Edit Product Type
      </Typography>
      <Grid2 container spacing={2} justifyContent={"center"}>
        {/* productType Name */}
        <Grid2 size={{ xs: 12 }}>
          <TextField
            label="Product Type Name"
            variant="outlined"
            fullWidth
            value={productTypeInfo.name}
            required
            onChange={(e) =>
              productTypeDispatch({
                type: "SET_FIELD",
                fieldName: "name",
                value: e.currentTarget.value,
              })
            }
          />
        </Grid2>
        <Grid2 container spacing={2} size={{ xs: 12 }}>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => {
                nav(-1);
              }}
            >
              Cancel
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch(
                  updateProductType({
                    productTypeId: productTypeInfo.id,
                    productTypeData: productTypeInfo,
                  })
                );
                nav(-1);
              }}
            >
              Save
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};
