import React from "react";
import DOMPurify from "dompurify";

interface IDisplayHtmlText {
  description: string;
}

export const DisplayHtmlText = ({ description }: IDisplayHtmlText) => {
  const sanitizedDescription = DOMPurify.sanitize(description);

  return <div dangerouslySetInnerHTML={{ __html: sanitizedDescription }} />;
};
