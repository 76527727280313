import React, { useEffect, useReducer, useState } from "react";
import { PrivateBrandData } from "../../../packages/cmsapi";
import { BrandReducer } from "./BrandReducer";
import { Box, Button, Grid2, TextField, Typography } from "@mui/material";
import { ISortableImageProps, SortableImage } from "../../Common/SortableImage";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { updateBrand } from "../../../store/asyncThunk/productThunk";
import { useNavigate, useParams } from "react-router-dom";
import { useRequestDataIfNeeded } from "../../../hooks/useRequestDataIfNeeded";

const emptyBrandState = { id: "", name: "NoBrand" } as PrivateBrandData;

export const EditBrand = () => {
  const { brandId } = useParams();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const brandInfoMap = useAppSelector((state) => state.brandInfoMap);

  const [brandInfo, brandDispatch] = useReducer(
    BrandReducer,
    brandInfoMap[brandId ?? ""] ?? emptyBrandState
  );

  const [image, setImage] = useState<ISortableImageProps | undefined>(
    brandInfo.pictureUrl
      ? ({
          id: "image",
          url: brandInfo.pictureUrl ?? "",
        } as ISortableImageProps)
      : undefined
  );

  useEffect(() => {
    if (brandInfoMap[brandId ?? ""]) {
      brandDispatch({
        type: "SET_BRAND",
        value: brandInfoMap[brandId ?? ""],
      });
      setImage({
        id: "image",
        url: brandInfo.pictureUrl ?? "",
      } as ISortableImageProps);
    }
  }, [brandInfoMap, brandId, brandInfo.pictureUrl]);

  const handleImageDelete = () => {
    setImage(undefined);
  };

  useRequestDataIfNeeded(["brands"]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const uploadedImage = {
        id: "uploaded image",
        url: URL.createObjectURL(file),
      } as ISortableImageProps;

      setImage(uploadedImage);
      setUploadedFile(file);
    }
  };

  const pictureSection = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {image && (
          <SortableImage
            key={image.id}
            id={image.id}
            url={image.url}
            onDelete={handleImageDelete}
            width={300}
            height={100}
          />
        )}
        <Box sx={{ margin: 5 }}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="upload-image"
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              Upload Image
            </Button>
          </label>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Edit Brand
      </Typography>

      {/* Indication for Deleted Brand */}
      {brandInfo.isDeleted && (
        <Typography variant="h6" color="error" gutterBottom>
          This brand has been deleted.
        </Typography>
      )}

      <Grid2 container spacing={2} justifyContent={"center"}>
        <Grid2
          size={{ xs: 12 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            overflowX: "auto",
          }}
        >
          {pictureSection()}
        </Grid2>

        {/* Brand Name */}
        <Grid2 size={{ xs: 12 }}>
          <TextField
            label="Brand Name"
            variant="outlined"
            fullWidth
            value={brandInfo.name}
            required
            onChange={(e) =>
              brandDispatch({
                type: "SET_FIELD",
                fieldName: "name",
                value: e.currentTarget.value,
              })
            }
          />
        </Grid2>
        <Grid2 container spacing={2} size={{ xs: 12 }}>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={() => {
                nav(-1);
              }}
            >
              Cancel
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                dispatch(
                  updateBrand({
                    brandId: brandInfo.id,
                    brandData: brandInfo,
                    file: uploadedFile,
                  })
                );
                nav(-1);
              }}
            >
              Save
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Box>
  );
};
