import { PrivateBrandData } from "../../../packages/cmsapi";

export type BrandAction =
  | { type: "SET_BRAND"; value: any }
  | { type: "SET_FIELD"; fieldName: keyof PrivateBrandData; value: any }
  | { type: "RESET"; payload: PrivateBrandData }
  | { type: "LOAD_BRAND"; payload: PrivateBrandData };

export const BrandReducer = (
  state: PrivateBrandData,
  action: BrandAction
): PrivateBrandData => {
  switch (action.type) {
    case "SET_BRAND":
      return { ...action.value };
    case "SET_FIELD":
      return { ...state, [action.fieldName]: action.value };
    case "RESET":
      return action.payload;
    case "LOAD_BRAND":
      return action.payload;
    default:
      return state;
  }
};
